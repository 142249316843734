const urlParams = new URLSearchParams(window.location.search);

function addWidget() {
  // eslint-disable-next-line no-undef
  vs.addWidget({
    apiKey: '8ff81a99ec736da0ff867697519d5116427d0f09', // Marui
    inpageContainers: ['#vs-inpage'],
    inpageMiniContainers: ['#vs-inpage-mini'],
    smartTableContainers: ['#vs-smart-table'],
    fittingRoomButtonContainers: ['#vs-fitting-room-button'],
    inpageLuxuryContainers: ['#vs-inpage-luxury'],
    region: 'jp',
    env: 'production',
    language: urlParams.get('language') || 'en',
    externalProductId: 'CB0043453601',
    onEvents: {
      'user-saw-product': () => {
        console.log('CustomIntegration: user-saw-product');
      },
      'user-saw-widget-button': () => {
        console.log('CustomIntegration: user-saw-widget-button');
      },
      'widget-ready': () => {
        console.log('CustomIntegration: widget-ready');
      },
      'user-opened-widget': () => {
        console.log('CustomIntegration: user-opened-widget');
      },
      'user-clicked-add-to-cart': () => {
        console.log('CustomIntegration: user-clicked-add-to-cart');
      },
    },
  });
}

// load integration, wait when ready and initialize Aoyama
var script = document.createElement('script');
script.src = 'https://static.api.virtusize.jp/a/aoyama/latest/integration.js';
script.onload = function () {
  addWidget();
};
document.getElementsByTagName('head')[0].appendChild(script);
